.clickable:hover {
  cursor: pointer; }

#__next {
  display: flex;
  min-height: 100vh;
  flex-direction: column; }

.Site-content {
  flex: 1; }

.my-footer {
  margin-top: 32px; }

.home-cover {
  background-image: url("/subtle_dots/subtle_dots.png");
  background-repeat: repeat; }

.how-works {
  background-image: url("/furley_bg/furley_bg.png");
  background-repeat: repeat; }

.button .icon:first-child,
.button .icon:last-child {
  margin-right: 1% !important;
  margin-left: 0px !important;
  padding: 2%; }

.earn-cal .field-label {
  flex-grow: 2; }

.earn-cal .salary .control {
  flex-grow: 1; }

.level.nav-social {
  justify-content: center; }
  .level.nav-social a {
    padding-right: 1em;
    padding-left: 1em; }

.navbar-dropdown .level.nav-social {
  justify-content: left; }
  .navbar-dropdown .level.nav-social .navbar-item {
    padding-right: 1em;
    padding-left: 1em; }

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden; }

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.job .timeline .timeline-header {
  justify-content: left; }

.company {
  padding-left: 5%; }

.modal.is-active {
  position: fixed;
  flex-direction: column; }
